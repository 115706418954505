import React from 'react';
import {useNavigate} from "react-router";
import styles from '@styles/components/Navbar.module.scss';
import {useDispatch} from "react-redux";
import {logout} from "@redux/actions/Auth.actions";
import {BranchesOutlined, LogoutOutlined} from "@ant-design/icons";

const Navbar: React.FC<any> = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const navigateToCountingLocation = () => {
        navigate('/counting-location');
    }
    const logoutAction = () => {
        dispatch(logout());
    }

    return <nav className={styles.navbar}>
        <div className={styles.left_bar}>
            <h2>FA Counting</h2>
        </div>
        <ul className={styles.right_bar}>
            <li>Sabit Kıymetler</li>
            <li onClick={navigateToCountingLocation}><BranchesOutlined/></li>
            <li onClick={logoutAction}><LogoutOutlined/></li>
        </ul>
    </nav>
}

export default Navbar;
