import {applyMiddleware, createStore} from 'redux';

import rootReducer from './reducers';
import {persistStore} from "redux-persist";

import createSagaMiddleware from 'redux-saga';
import rootSaga from "@redux/sagas";

const bindMiddleware = (middleware) => {

    if (process.env.NODE_ENV !== 'production') {
        const {composeWithDevTools} = require('redux-devtools-extension');

        return composeWithDevTools(applyMiddleware(...middleware));
    }

    return applyMiddleware(...middleware);
};

export const makeStore = () => {

    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(rootReducer, bindMiddleware([sagaMiddleware]));

    (store as any).sagaTask = sagaMiddleware.run(rootSaga);

    return store;
};

export const store = makeStore();

export const persistor = persistStore(store);
