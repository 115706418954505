import axios from 'axios';
import {API} from '@utils/axiosInstances';

const setAuthToken = token => {
    if (token) {
        API.defaults.headers.common['x-fa-token'] = token;
        axios.defaults.headers.common['x-fa-token'] = token;
    } else {
        delete API.defaults.headers.common['x-fa-token'];
        delete axios.defaults.headers.common['x-fa-token'];
    }
};

export default setAuthToken;
