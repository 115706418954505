export const LOGIN = 'LOGIN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const GET_ME = 'GET_ME';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAILED = 'GET_ME_FAILED';
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const VALIDATE_EMAIL_REQUEST = 'VALIDATE_EMAIL_REQUEST';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAILED = 'VALIDATE_EMAIL_FAILED';
export const REGISTER = 'REGISTER';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
export const FORGOT_PASSWORD_VERIFY = 'FORGOT_PASSWORD_VERIFY';
export const FORGOT_PASSWORD_VERIFY_REQUEST = 'FORGOT_PASSWORD_VERIFY_REQUEST';
export const FORGOT_PASSWORD_VERIFY_SUCCESS = 'FORGOT_PASSWORD_VERIFY_SUCCESS';
export const FORGOT_PASSWORD_VERIFY_FAILED = 'FORGOT_PASSWORD_VERIFY_FAILED';
export const SET_NETWORK_STATUS = 'SET_NETWORK_STATUS';
export const RESET_NETWORK_STATUS = 'RESET_NETWORK_STATUS';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SET_COUNTING_LOCATION = 'SET_COUNTING_LOCATION';
export const SET_IS_LOCATED = 'SET_IS_LOCATED';
export const ERROR_OCCURRED = 'ERROR_OCCURRED';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const login = payload => ({type: LOGIN, payload});

export const loginRequest = () => ({type: LOGIN_REQUEST});

export const loginSuccess = (data) => ({type: LOGIN_SUCCESS, data});

export const loginFailed = (data) => ({type: LOGIN_FAILED, data});

export const logout = () => ({type: LOGOUT});

export const logoutRequest = () => ({type: LOGOUT_REQUEST});

export const logoutSuccess = (data) => ({type: LOGOUT_SUCCESS, data});

export const logoutFailed = (data) => ({type: LOGOUT_FAILED, data});

export const getMe = (cb) => ({type: GET_ME, cb});

export const getMeSuccess = (data) => ({type: GET_ME_SUCCESS, data});

export const getMeFailed = (data) => ({type: GET_ME_FAILED, data});

export const validateEmail = (payload) => ({type: VALIDATE_EMAIL, payload});

export const validateEmailRequest = () => ({type: VALIDATE_EMAIL_REQUEST});

export const validateEmailSuccess = (data?) => ({type: VALIDATE_EMAIL_SUCCESS, data});

export const validateEmailFailed = (data) => ({type: VALIDATE_EMAIL_FAILED, data});

export const register = (payload) => ({
    type: REGISTER, payload: {
        ...payload,
        docId: payload.docId ? payload.docId : ''
    }
});

export const registerRequest = () => ({type: REGISTER_REQUEST});

export const registerSuccess = (data) => ({type: REGISTER_SUCCESS, data});

export const registerFailed = (data) => ({type: REGISTER_FAILED, data});

export const changePassword = (payload, cb) => ({type: CHANGE_PASSWORD, payload, cb});

export const changePasswordRequest = () => ({type: CHANGE_PASSWORD_REQUEST});

export const changePasswordSuccess = (data) => ({type: CHANGE_PASSWORD_SUCCESS, data});

export const changePasswordFailed = (data) => ({type: CHANGE_PASSWORD_FAILED, data});

export const forgotPassword = (payload, cb) => ({type: FORGOT_PASSWORD, payload, cb});

export const forgotPasswordRequest = () => ({type: FORGOT_PASSWORD_REQUEST});

export const forgotPasswordSuccess = (data) => ({type: FORGOT_PASSWORD_SUCCESS, data});

export const forgotPasswordFailed = (data) => ({type: FORGOT_PASSWORD_FAILED, data});

export const forgotPasswordVerify = (payload, cb) => ({type: FORGOT_PASSWORD_VERIFY, payload, cb});

export const forgotPasswordVerifyRequest = () => ({type: FORGOT_PASSWORD_VERIFY_REQUEST});

export const forgotPasswordVerifySuccess = (data) => ({type: FORGOT_PASSWORD_VERIFY_SUCCESS, data});

export const forgotPasswordVerifyFailed = (data) => ({type: FORGOT_PASSWORD_VERIFY_FAILED, data});

export const setNetworkStatus = (payload, cb) => ({type: SET_NETWORK_STATUS, payload, cb});

export const resetNetworkStatus = () => ({type: RESET_NETWORK_STATUS});

export const refreshToken = payload => ({type: REFRESH_TOKEN, payload});

export const setCountingLocation = payload => ({type: SET_COUNTING_LOCATION, payload});

export const setIsLocated = payload => ({type: SET_IS_LOCATED, payload});

export const errorOccurred = payload => ({type: ERROR_OCCURRED, payload});

export const clearError = () => ({type: CLEAR_ERROR});
