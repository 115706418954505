import {API} from '@utils/axiosInstances';
import setAuthToken from '@utils/setAuthToken';

import {
    LOGIN,
    loginRequest,
    loginSuccess,
    loginFailed,
    LOGOUT,
    logoutRequest,
    logoutSuccess,
    logoutFailed,
    GET_ME,
    getMeSuccess,
    getMeFailed,
    VALIDATE_EMAIL,
    validateEmailRequest,
    validateEmailSuccess,
    validateEmailFailed,
    REGISTER,
    registerRequest,
    registerSuccess,
    registerFailed,
    CHANGE_PASSWORD,
    changePasswordRequest,
    changePasswordSuccess,
    changePasswordFailed,
    FORGOT_PASSWORD,
    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailed,
    FORGOT_PASSWORD_VERIFY,
    forgotPasswordVerifyRequest,
    forgotPasswordVerifySuccess,
    forgotPasswordVerifyFailed
} from "@redux/actions/Auth.actions";

import {ERROR_RESPONSE} from "@utils/constants/error.config";
import {takeEvery, takeLatest, put, call} from 'redux-saga/effects';
import {setIsCountingSelected, setSelectedCounting} from "@redux/actions/Core.actions";

const delay = (ms) => new Promise(res => setTimeout(res, ms))


export function* login() {
    yield takeLatest(LOGIN, loginAsync);
}

export function* logout() {
    yield takeLatest(LOGOUT, logoutAsync);
}

export function* getMe() {
    yield takeEvery(GET_ME, getMeAsync);
}

export function* validateEmail() {
    yield takeEvery(VALIDATE_EMAIL, validateEmailAsync);
}

export function* register() {
    yield takeEvery(REGISTER, registerAsync);
}

export function* changePassword() {
    yield takeEvery(CHANGE_PASSWORD, changePasswordAsync);
}

export function* forgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPasswordAsync);
}

export function* forgotPasswordVerify() {
    yield takeEvery(FORGOT_PASSWORD_VERIFY, forgotPasswordVerifyAsync);
}

export function* loginAsync(action) {
    try {
        yield put(loginRequest());
        const response = yield call(() => API.post('/login', JSON.stringify(action.payload)));
        if (response.data.token) {
            localStorage.setItem('fa_user_token', response?.data.token);
            setAuthToken(response?.data?.token);
            yield put(loginSuccess(response?.data));
            window.location.href = '/counting-location';
        }
    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error === "EmailOrPasswordWrongError") {
                yield put(loginFailed(ERROR_RESPONSE(null, 'E-posta veya şifreniz hatalı')));
            } else {
                yield put(loginFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(loginFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* logoutAsync() {
    try {
        yield put(logoutRequest());
        const response = yield call(() => API.delete('/users/me/logout'));
        console.log('RESPONSE', response);
        if (response) {
            console.log('hi');
            yield put(setIsCountingSelected(false));
            localStorage.removeItem('fa_user_token');
            setAuthToken(null);
            yield put(logoutSuccess(response?.data));
            yield put(setSelectedCounting({
                location: null,
                branch: null,
                unit: null,
                subUnit: null
            }));

        }
    } catch (error) {
        const {response} = error;
        if (response) {
            yield put(logoutFailed(ERROR_RESPONSE(response.data)));
        } else {
            yield put(logoutFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* getMeAsync(action) {
    let isOk = false;

    try {
        const response = yield call(() => {
            return fetch("/api/me", {
                method: "GET"
            }).then(res => res.json());
        });

        if (response.result === "success") {
            if (Object.prototype.hasOwnProperty.call(response, 'data')) {
                isOk = true;
                yield put(getMeSuccess(response?.data));
            } else {
                yield put(getMeFailed(ERROR_RESPONSE(response)));
            }
        } else {
            yield put(getMeFailed(ERROR_RESPONSE(response.data)));
        }

        if (action.cb)
            action.cb(isOk);

    } catch (error) {
        yield put(getMeFailed(ERROR_RESPONSE(error)));
        if (action.cb)
            action.cb(isOk);
    }
}

export function* validateEmailAsync(action) {
    try {
        yield put(validateEmailRequest());
        yield delay(1000);
        const response = yield call(() => {
            return fetch("/api/auth/emailValidate", {
                body: JSON.stringify(action.payload),
                method: "POST"
            }).then(res => res.json());
        });

        if (response.result === "success") {
            yield put(validateEmailSuccess());
        } else {
            if (response.error === "InvalidVerificationCodeError") {
                yield put(validateEmailFailed(ERROR_RESPONSE(null, 'Doğrulama kodu yanlış, lütfen tekrar deneyiniz.')));
            } else {
                yield put(validateEmailFailed(ERROR_RESPONSE()));
            }
        }
    } catch (error) {
        yield put(validateEmailFailed(ERROR_RESPONSE(error)));
    }
}

export function* registerAsync(action) {
    try {
        yield put(registerRequest());
        yield delay(1000);
        const response = yield call(() => {
            return fetch("/api/auth/register", {
                body: JSON.stringify(action.payload),
                method: "POST"
            }).then(res => res.json());
        });

        if (response.result === "success") {
            if (Object.prototype.hasOwnProperty.call(response, 'data')) {

                // setCookies("dp_user_is_verified", response.data?.isVerified ? 1 : 0);
                // setCookies("dp_user_token", response.data?.token || 0);
                // setCookies("dp_user_refresh_token", response.data?.refreshToken || 0);

                yield put(registerSuccess(response.data));
            } else {
                yield put(registerFailed(ERROR_RESPONSE()));
            }
        } else {
            if (response.error === "AlreadyExistsEmailOrTcknError") {
                yield put(registerFailed(ERROR_RESPONSE(null, 'Bu e-posta ya da T.C. Kimlik No ile daha önce bir hesap oluşturulmuş. Lütfen giriş yapınız.')));
            } else {
                yield put(registerFailed(ERROR_RESPONSE()));
            }
        }
    } catch (error) {
        yield put(registerFailed(ERROR_RESPONSE(error)));
    }
}

export function* changePasswordAsync(action) {
    let isOk = false;

    try {
        yield put(changePasswordRequest());
        yield delay(1000);
        const response = yield call(() => {
            return fetch("/api/auth/change-password", {
                body: JSON.stringify(action.payload),
                method: "PUT"
            }).then(res => res.json());
        });

        if (response.result === "success") {
            yield put(changePasswordSuccess(response.data));
            isOk = true;
        } else {
            yield put(changePasswordFailed(ERROR_RESPONSE()));
        }

        if (action.cb)
            action.cb(isOk);

    } catch (error) {
        yield put(changePasswordFailed(ERROR_RESPONSE(error)));
    }
}

export function* forgotPasswordAsync(action) {
    let isOk = false;

    try {
        yield put(forgotPasswordRequest());
        yield delay(1000);
        const response = yield call(() => {
            return fetch("/api/auth/forgot-password", {
                body: JSON.stringify(action.payload),
                method: "POST"
            }).then(res => res.json());
        });

        if (response.result === "success") {
            yield put(forgotPasswordSuccess(response.data));
            isOk = true;
        } else {
            if (response.error === "NoUsersFoundError") {
                yield put(forgotPasswordSuccess(null));
                isOk = true;
                // yield put(forgotPasswordFailed(ERROR_RESPONSE(null, 'Bu e-posta ile herhangi bir kullanıcı bulunamadı.')));
            } else {
                yield put(forgotPasswordFailed(ERROR_RESPONSE()));
            }
        }

        if (action.cb)
            action.cb(isOk);

    } catch (error) {
        yield put(forgotPasswordFailed(ERROR_RESPONSE(error)));
    }
}

export function* forgotPasswordVerifyAsync(action) {
    let isOk = false;
    console.log('HERE?');
    try {
        yield put(forgotPasswordVerifyRequest());
        yield delay(1000);
        const response = yield call(() => {
            return fetch("/api/auth/forgot-password-verify", {
                body: JSON.stringify(action.payload),
                method: "PUT"
            }).then(res => res.json());
        });

        if (response.result === "success") {
            yield put(forgotPasswordVerifySuccess(response.data));
            isOk = true;
        } else {
            if (response.error === "NoUsersFoundError") {
                yield put(forgotPasswordVerifyFailed(ERROR_RESPONSE(null, 'Bu e-posta ile herhangi bir kullanıcı bulunamadı.')));
            }
            yield put(forgotPasswordVerifyFailed(ERROR_RESPONSE()));
        }

        if (action.cb)
            action.cb(isOk);

    } catch (error) {
        console.log('===');
        yield put(forgotPasswordVerifyFailed(ERROR_RESPONSE(error)));
    }
}
