import React from 'react';
import styles from '@styles/components/Layout.module.scss';
import Navbar from "@components/molecules/Navbar";

const Layout: React.FC<any> = ({children}) => {
    return <div className={styles.layout}>
        <Navbar/>
        <div className={styles.content_wrapper}>
            {children}
        </div>
    </div>
}

export default Layout;
