import axios from 'axios';

export const ENVIRONMENT_TAG = process.env.REACT_APP_ENV;
export const API_URL = process.env.REACT_APP_API_URL;

console.log('API_URL:', API_URL);

export const API = axios.create({
    baseURL: API_URL,
    timeout: 10000,
    withCredentials: false,
    headers: {
        "Content-Type": "application/json",
        'x-fa-token': localStorage.getItem('fa_user_token')
    }
});
