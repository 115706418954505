export const SET_SELECTED_COUNTING = 'SET_SELECTED_COUNTING';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILED = 'GET_COMPANIES_FAILED';

export const GET_BRANCHES = 'GET_BRANCHES';
export const GET_BRANCHES_REQUEST = 'GET_BRANCHES_REQUEST';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_FAILED = 'GET_BRANCHES_FAILED';

export const GET_UNITS = 'GET_UNITS';
export const GET_UNITS_REQUEST = 'GET_UNITS_REQUEST';
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
export const GET_UNITS_FAILED = 'GET_UNITS_FAILED';

export const GET_SUB_UNITS = 'GET_SUB_UNITS';
export const GET_SUB_UNITS_REQUEST = 'GET_SUB_UNITS_REQUEST';
export const GET_SUB_UNITS_SUCCESS = 'GET_SUB_UNITS_SUCCESS';
export const GET_SUB_UNITS_FAILED = 'GET_SUB_UNITS_FAILED';

export const SET_IS_COUNTING_SELECTED = 'SET_IS_COUNTING_SELECTED';

export const ERROR_OCCURRED = 'ERROR_OCCURRED';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const setSelectedCounting = payload => ({type: SET_SELECTED_COUNTING, payload});


export const getCompanies = () => ({type: GET_COMPANIES});

export const getCompaniesRequest = () => ({type: GET_COMPANIES_REQUEST});

export const getCompaniesSuccess = (data) => ({type: GET_COMPANIES_SUCCESS, data});

export const getCompaniesFailed = (data) => ({type: GET_COMPANIES_FAILED, data});


export const getBranches = (companyId) => ({type: GET_BRANCHES, companyId});

export const getBranchesRequest = () => ({type: GET_BRANCHES_REQUEST});

export const getBranchesSuccess = (data) => ({type: GET_BRANCHES_SUCCESS, data});

export const getBranchesFailed = (data) => ({type: GET_BRANCHES_FAILED, data});


export const getUnits = (companyId, branchId) => ({type: GET_UNITS, companyId, branchId});

export const getUnitsRequest = () => ({type: GET_UNITS_REQUEST});

export const getUnitsSuccess = (data) => ({type: GET_UNITS_SUCCESS, data});

export const getUnitsFailed = (data) => ({type: GET_UNITS_FAILED, data});


export const getSubUnits = (unitId) => ({type: GET_SUB_UNITS, unitId});

export const getSubUnitsRequest = () => ({type: GET_SUB_UNITS_REQUEST});

export const getSubUnitsSuccess = (data) => ({type: GET_SUB_UNITS_SUCCESS, data});

export const getSubUnitsFailed = (data) => ({type: GET_SUB_UNITS_FAILED, data});

export const setIsCountingSelected = payload => ({type: SET_IS_COUNTING_SELECTED, payload});


export const errorOccurred = payload => ({type: ERROR_OCCURRED, payload});

export const clearError = () => ({type: CLEAR_ERROR});
