import React, {useEffect} from 'react';

import styles from '@styles/pages/Login.module.scss';

import {useDispatch} from "react-redux";
import {login} from "@redux/actions/Auth.actions";
import {Button, Form, Input} from "antd";

const LoginPage: React.FC<any> = () => {

    const dispatch = useDispatch();
    const token = localStorage.getItem('fa_user_token');

    useEffect(() => {
        console.log('TOKEN', token);
        if (token) {
            window.location.href = '/counting-location';
        }
    }, [token]);

    const onFinish = (loginForm: any) => {
        dispatch(login({
            ...loginForm,
            phone: loginForm.phone.startsWith('+90') ? loginForm.phone.replace(/\s/g, '') : '+90' + loginForm.phone.replace(/\s/g, '')
        }));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return <section className={styles.login}>
        <div className={`${styles.container} p-30`}>

            {/*<div className={styles.logo}>FA COUNTING</div>*/}

            <Form
                name="basic"
                className={styles.login_form}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 750}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Telefon"
                    name="phone"
                    className={styles.login_input}
                    rules={[{required: true, message: 'Lütfen telefon numaranızı giriniz.'}]}
                >
                    <Input allowClear/>
                </Form.Item>

                <Form.Item
                    label="Şifre"
                    name="password"
                    className={styles.login_input}
                    rules={[{required: true, message: 'Lütfen şifrenizi giriniz.'}]}
                >
                    <Input.Password allowClear/>
                </Form.Item>

                <Form.Item className={"d-flex jc-end w-100 mb-10"}>
                    <Button type="primary" htmlType="submit">
                        Giriş Yap
                    </Button>
                </Form.Item>

            </Form>
        </div>
    </section>
}

export default LoginPage;
