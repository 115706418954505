import {persistCombineReducers, persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import AuthReducer from '@redux/reducers/Auth.reducer';
import CoreReducer from "@redux/reducers/Core.reducer";
import FixedAssetsReducer from "@redux/reducers/FixedAssets.reducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'core'] // which reducer want to store
};

const rootReducer = persistCombineReducers(persistConfig, {
    auth: AuthReducer,
    core: CoreReducer,
    fixedAssets: FixedAssetsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default rootReducer;
