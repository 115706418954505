export default function (url, photo, callback) {
    const formData = new FormData();
    formData.append('file', photo);

    const request = new XMLHttpRequest();
    request.onreadystatechange = function () {
        if (request.readyState === 4) {
            callback(request.response);
        }
    };
    request.open('POST', url);
    request.setRequestHeader('X-APP-TOKEN', '5ec12b061a6ea766310373714fc7e331');
    request.responseType = 'json';
    request.send(formData);
};
