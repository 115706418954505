import {Button, Space} from "antd";
import React from "react";

const openNotification = ({api, title, description, onAccept, onAcceptTitle, onDecline, onDeclineTitle}) => {
    const key = `open${Date.now()}`;
    const btn = (
        <Space>
            <Button type="link" size="small" onClick={() => {
                if (onDecline) {
                    onDecline();
                }
                api.destroy();
            }}>
                {onDeclineTitle || 'İptal'}
            </Button>
            <Button type="primary" size="small" onClick={() => {
                if (onAccept) {
                    onAccept();
                }
                api.destroy();
            }}>
                {onAcceptTitle || 'Uygula'}
            </Button>
        </Space>
    );
    api.open({
        message: title,
        description: description,
        btn,
        key
    });
};

export default openNotification;
