export const AddPictureIcon = () => {
    return (
        <svg width="550pt" height="300pt" version="1.1" viewBox="100 50 550 500" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
                d="m367.5 169.17c-70.758 0-128.33 57.574-128.33 128.33s57.574 128.33 128.33 128.33 128.33-57.574 128.33-128.33-57.574-128.33-128.33-128.33zm0 245c-64.328 0-116.67-52.336-116.67-116.67 0-64.328 52.336-116.67 116.67-116.67 64.328 0 116.67 52.336 116.67 116.67 0 64.328-52.34 116.67-116.67 116.67z"/>
            <path
                d="m134.17 227.5v210c0 3.2188 2.6133 5.832 5.832 5.832h455c3.2188 0 5.832-2.6133 5.832-5.832v-280c0-3.2188-2.6133-5.832-5.832-5.832h-169.17v-29.168c0-3.2188-2.6133-5.832-5.832-5.832h-105c-3.2188 0-5.832 2.6133-5.832 5.832v29.168h-99.168c-3.2188 0-5.832 2.6133-5.832 5.832v64.168h-64.168c-3.2188 0-5.832 2.6133-5.832 5.832zm186.66-99.168h93.332v23.332h-93.332zm-175 105h64.168c3.2188 0 5.832-2.6133 5.832-5.832v-64.168h373.34v268.33h-443.33z"/>
            <path
                d="m560 204.17c-3.2188 0-5.832 2.6133-5.832 5.832v175c0 3.2188 2.6133 5.832 5.832 5.832s5.832-2.6133 5.832-5.832v-175c0-3.2188-2.6133-5.832-5.832-5.832z"/>
            <path
                d="m105 163.33h29.168v29.168c0 3.2188 2.6133 5.832 5.832 5.832s5.832-2.6133 5.832-5.832v-29.168h29.168c3.2188 0 5.832-2.6133 5.832-5.832s-2.6133-5.832-5.832-5.832h-29.168v-29.168c0-3.2188-2.6133-5.832-5.832-5.832s-5.832 2.6133-5.832 5.832v29.168h-29.168c-3.2188 0-5.832 2.6133-5.832 5.832s2.6133 5.832 5.832 5.832z"/>
        </svg>
    )
}

export const BackIcon = () => (
    <svg width="500pt" height="500pt" version="1.1" viewBox="100 25 500 500" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
        <path
            d="m526.4 225.68h-208.88l45.922-45.922c22.961-22.961 22.961-59.922 0-82.879-22.961-22.961-59.922-22.961-82.879 0l-145.6 145.6c-22.961 22.961-22.961 59.922 0 82.879l145.6 145.6c22.961 22.961 59.922 22.961 82.879 0 22.961-22.961 22.961-59.922 0-82.879l-44.801-44.801h208.32c32.48 0 58.238-26.32 58.238-58.238 0-33.602-26.32-59.363-58.801-59.363z"/>
    </svg>
)
