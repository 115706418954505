import React, {Suspense, useEffect, useState} from 'react';

import styles from '@styles/pages/NewFixedAsset.module.scss';
import {Button, Checkbox, Form, Input, notification, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from 'antd';

import {
    createFixedAsset,
    fetchNewFaData,
    getProductSubGroups,
    getProductSubSubGroups
} from "@redux/actions/FixedAssets.actions";
import {AddPictureIcon, BackIcon} from "@components/molecules/Icons";
import alert from "@utils/alert";
import {Link} from "react-router-dom";
import uploadS3File from "@utils/uploadS3File";

const {Option} = Select;

const NewFixedAssetPage: React.FC<any> = () => {

    const [dummy, setDummy] = useState('');
    const [payloadIsLoading, setPayloadIsLoading] = useState(true);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const {
        employees,
        productGroups,
        productSubGroups,
        productSubSubGroups
    } = useSelector((state: any) => state.fixedAssets);
    const {selectedCounting} = useSelector((state: any) => state.core);

    const [extraDescriptionToggle, setExtraDescriptionToggle] = useState(false);
    const [newFaPicture, setNewFaPicture] = useState(undefined);

    useEffect(() => {
        dispatch(fetchNewFaData(() => {
            setPayloadIsLoading(false);
        }));
        console.log('Formie', form.getFieldValue('image'));
    }, []);

    const onProductGroupChange = (value) => {
        dispatch(getProductSubGroups(value, (hasError, data) => {
            console.log('H', hasError);
            console.log('data', data);
            console.log('val', value);
            console.log('condition', hasError === false ? data[0]?.id : undefined);
            form.setFieldsValue({
                ...form.getFieldsValue(),
                productSubGroup: hasError === false ? data[0]?.id : undefined,
                productSubSubGroup: undefined
            });

            if (hasError === false) {
                dispatch(getProductSubSubGroups(data[0]?.id, (hasError, data) => {
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        productSubSubGroup: hasError === false ? data[0]?.id : undefined
                    });
                }));
            }

            console.log('formie', form.getFieldsValue());
        }));
    }

    const onProductSubGroupChange = (value) => {
        dispatch(getProductSubSubGroups(value, (hasError, data) => {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                productSubSubGroup: hasError === false ? data[0]?.id : undefined
            });
        }));
    }

    const onPictureChange = (_e) => {
        console.log('hello');
        const URL = `${process.env.REACT_APP_PANEL_URL + '/api/s3-uploads'}?folderName=${selectedCounting?.location?.value}`
        uploadS3File(URL, _e.currentTarget.files[0], (response) => {
            console.log('resp', response);
            if (response) {
                form.setFieldsValue({
                    ...form.getFieldsValue(),
                    image: response.location // e.target.files[0]
                });
                setNewFaPicture(response.location);
            } else {
                alert('error', {description: 'Resim yüklenemedi.'});
            }
        });
    }

    const onProductStatusChange = () => {

    }

    const onFinish = (values: any) => {
        console.log(values);

        dispatch(createFixedAsset({
            ...values,
            description2: values.description2 || ''
        }, () => {
            form.resetFields();
            setNewFaPicture(undefined);
            alert('success', {description: 'Sabit Kıymet başarılı bir şekilde eklendi.'});
        }));
    };

    const onReset = () => {
        form.resetFields();
    };

    const selectFilterOption = (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0

    return <section className={styles.new_fa_container}>
        <div className={styles.new_fa_form}>
            <h1 className={styles.title}>
                <Link to={"/"} className={styles.icon}>
                    <BackIcon/>
                </Link>
                Yeni Sabit Kıymet Formu
            </h1>

            {payloadIsLoading === false &&
              <Form
                name="basic"
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 600}}
                form={form}
                className={styles.new_fa_form_content}
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                    "productStatus": `Sağlam`,
                    "expenseType": `Capex`,
                    "productGroup": Array.isArray(productGroups) ? productGroups[0]?.id : undefined,
                    "productSubGroup": Array.isArray(productSubGroups) ? productSubGroups[0]?.id : undefined,
                    "productSubSubGroup": Array.isArray(productSubSubGroups) ? productSubSubGroups[0]?.id : undefined,
                    "employee": (Array.isArray(employees) && employees?.length > 0) ? employees[0]?.id : undefined
                }}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Ürün Resmi"
                      name="image"
                      rules={[{required: true, message: 'Ürün resmi ekleyiniz'}]}
                    >
                      <label htmlFor={"add_picture_input"}>
                        <div className={styles.add_picture_container}>
                            {newFaPicture ?
                                <img alt={"new_fa_picture"} width={50} height={50}
                                     src={form.getFieldValue('image')}/>
                                :
                                <AddPictureIcon/>
                            }
                        </div>
                      </label>
                      <input onChange={onPictureChange} className={"d-none"} style={{display: 'none'}}
                             id={"add_picture_input"}
                             type={"file"}/>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Barkodu"
                      name="barcode"
                      rules={[{required: true, message: 'Lütfen barkod giriniz'}]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item name="productStatus" label="Ürün Durumu" rules={[{required: true}]}>
                      <Select
                        placeholder="Ürünün durumunu belirleyiniz"
                        onChange={onProductStatusChange}
                        allowClear
                      >
                        <Option value="Sağlam">Sağlam</Option>
                        <Option value="Arızalı">Arızalı</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="expenseType" label="Gider Türü" rules={[{required: true}]}>
                      <Select
                        placeholder="Gider türü belirleyiniz"
                        allowClear
                      >
                        <Option value="Capex">Capex</Option>
                        <Option value="Opex">Opex</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="productGroup" label="Ürün Grupları" rules={[{required: true}]}>
                  <Select
                    showSearch
                    filterOption={selectFilterOption}
                    placeholder={"Ürün grubu seçiniz"}
                    className={"select"}
                    onChange={onProductGroupChange}
                    options={Array.isArray(productGroups) && productGroups?.map((pg) => ({
                        label: pg.name,
                        value: pg.id
                    }))}
                    allowClear
                  />
                </Form.Item>

                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item name="productSubGroup" label="Ürün Alt Grupları" rules={[{required: true}]}>
                      <Select
                        showSearch
                        filterOption={selectFilterOption}
                        placeholder={"Ürün alt grubu seçiniz"}
                        className={"select"}
                        onChange={onProductSubGroupChange}
                        options={Array.isArray(productSubGroups) && productSubGroups?.map((psg) => ({
                            label: psg.name,
                            value: psg.id
                        }))}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="productSubSubGroup" label="Alt Grubun Alt Grupları"
                               rules={[{required: true}]}>
                      <Select
                        showSearch
                        filterOption={selectFilterOption}
                        placeholder={"Alt grubun alt grubunu seçiniz"}
                        className={"select"}
                        options={Array.isArray(productSubSubGroups) && productSubSubGroups?.map((pssg) => ({
                            label: pssg.name,
                            value: pssg.id
                        }))}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item
                      label="Marka"
                      name="brand"
                      rules={[{required: false, message: 'Marka yazınız'}]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Model"
                      name="model"
                      rules={[{required: false, message: 'Model yazınız'}]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item
                      label="Seri No"
                      name="serialNo"
                      rules={[{required: false, message: 'Seri No yazınız'}]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Çalışan"
                      name="employee"
                      rules={[{required: true, message: 'Çalışan giriniz'}]}
                    >
                      <Select
                        showSearch
                        filterOption={selectFilterOption}
                        placeholder={"Çalışan seçiniz"}
                        className={"select"}
                        defaultValue={employees[0]?.id}
                        options={employees?.map((e) => ({
                            label: `${e.first_name} ${e.last_name}`,
                            value: e.id
                        }))}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label="Açıklama"
                  name="description"
                  rules={[{required: true, message: 'Açıklama yazınız'}]}
                >
                  <Input.TextArea/>
                </Form.Item>

                <Checkbox id={'extraDescriptionCheckbox'} onChange={() => {
                    setExtraDescriptionToggle(!extraDescriptionToggle);
                }} checked={extraDescriptionToggle}>Ek Açıklama</Checkbox>

                  {extraDescriptionToggle === true &&
                    <Form.Item
                      label="Açıklama 2"
                      name="description2"
                      rules={[{required: false, message: 'Açıklama 2 yazınız'}]}
                    >
                      <Input.TextArea/>
                    </Form.Item>
                  }

                <Row className={'pbs-20 pbe-10'}>
                  <Col span={24}>
                    <Button className={'w-100'} type="primary" htmlType="submit">
                      Oluştur
                    </Button>
                  </Col>
                </Row>

                  {dummy.toString()}
              </Form>
            }
        </div>
    </section>
}

export default NewFixedAssetPage;
