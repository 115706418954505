import React, {useEffect} from 'react';
import styles from '@styles/pages/Dashboard.module.scss';
import MiniCard from "@components/molecules/MiniCard";
import {useDispatch, useSelector} from "react-redux";
import Table from "@components/organisms/Table";
import Layout from "@components/organisms/Layout";
import {deleteFixedAsset, getFixedAssets} from "@redux/actions/FixedAssets.actions";
import {useNavigate} from "react-router";
import {Button, notification} from "antd";
import openNotification from "@utils/openNotification";
import {Link} from "react-router-dom";

const DashboardPage: React.FC<any> = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectedCounting = useSelector((state: any) => state.core.selectedCounting);
    const fixedAssets = useSelector((state: any) => state.fixedAssets.fixedAssets);

    const [notificationApi, notificationContextHolder] = notification.useNotification();

    useEffect(() => {
        dispatch(getFixedAssets());
    }, []);

    useEffect(() => {
        console.log('FA', fixedAssets);
    }, [fixedAssets]);

    return <Layout>
        <section className={styles.dashboard}>
            <div className={styles.mini_cards}>
                <MiniCard title={"Şirket"} label={selectedCounting?.location?.label}/>
                <MiniCard title={"Şube"} label={selectedCounting?.branch?.label}/>
                <MiniCard title={"Birim"} label={selectedCounting?.unit?.label}/>
                <MiniCard title={"Alt Birim"} label={selectedCounting?.subUnit?.label}/>
            </div>
            <div className={styles.stats_mobile}>
                <div className={styles.stat}>
                    <div className={styles.block}>
                        <h4 className={styles.title}>Şirket</h4>
                        <h5 className={styles.content}>{selectedCounting?.location?.label}</h5>
                    </div>
                    <div className={styles.block}>
                        <h4 className={styles.title}>Şube</h4>
                        <h5 className={styles.content}>{selectedCounting?.location?.label}</h5>
                    </div>
                    <div className={styles.block}>
                        <h4 className={styles.title}>Birim</h4>
                        <h5 className={styles.content}>{selectedCounting?.unit?.label}</h5>
                    </div>
                    <div className={styles.block}>
                        <h4 className={styles.title}>Alt Birim</h4>
                        <h5 className={styles.content}>{selectedCounting?.subUnit?.label}</h5>
                    </div>
                </div>
            </div>
            <div className={styles.fa_table}>
                <div className={styles.actions}>
                    <div className={styles.search}>
                        <label>Ara: </label>
                        <input/>
                    </div>
                    <Button type={"primary"} onClick={() => {
                        navigate('/new-fa');
                    }}>Yeni Sabit Kıymet
                    </Button>
                </div>
                <Table columns={
                    [
                        {
                            title: 'Barkod',
                            dataIndex: 'barcode',
                            key: 'barcode',
                        },
                        {
                            title: 'Birim',
                            dataIndex: 'unitName',
                            key: 'unitName',
                        },
                        {
                            title: 'Alt Birim',
                            dataIndex: 'subUnitName',
                            key: 'subUnitName',
                        },
                        {
                            title: 'Ürün Durumu',
                            dataIndex: 'productStatus',
                            key: 'productStatus',
                        },
                        {
                            title: 'Ürün Grupları',
                            dataIndex: 'productGroupName',
                            key: 'productGroupName',
                        },
                        {
                            title: 'Çalışan',
                            dataIndex: 'employeeName',
                            key: 'employeeName',
                            render: (_, {
                                employeeFirstName,
                                employeeLastName
                            }) => (<>{employeeFirstName} {employeeLastName}</>)
                        },
                        {
                            title: 'Resim',
                            dataIndex: 'image',
                            key: 'image',
                            render: (_, {image}) => (<img alt={`fa-thumbnail`} src={image} height={50} width={75}/>)
                        },
                        {
                            title: 'Tarih',
                            dataIndex: 'createdAt',
                            key: 'createdAt',
                            render: (_, {createdAt}) => (<>{createdAt || '-'}</>)
                        },
                        {
                            title: 'Durum',
                            dataIndex: 'isActive',
                            key: 'isActive',
                            render: (_, {isActive}) => (<>{isActive ? 'Aktif' : 'Pasif'}</>)
                        },
                        {
                            title: 'İşlemler',
                            dataIndex: 'actions',
                            key: 'actions',
                            render: (_, {id}) => (<div className={"d-flex fd-column gap-5"}>
                                <Link to={`/fa-detail/${id}`}><Button>Detay</Button></Link>
                                <Button onClick={() => {
                                    openNotification({
                                        api: notificationApi,
                                        title: 'Emin Misiniz ?',
                                        description: 'Bu sabit kıymet ürününü silmek istediğinizden emin misinzi?',
                                        onAccept: () => {
                                            dispatch(deleteFixedAsset(id));
                                        },
                                        onAcceptTitle: 'Sil',
                                        onDecline: null,
                                        onDeclineTitle: 'Vazgeç'
                                    });
                                }}>Sil</Button>
                            </div>)
                        },
                    ]
                } data={fixedAssets}/>
            </div>

            <div className={styles.fa_cards}>
                <Button type={"primary"} onClick={() => {
                    navigate('/new-fa');
                }}>Yeni Sabit Kıymet
                </Button>
                {fixedAssets?.map(fixedAsset => (
                    <div className={styles.card}>
                        <div className={styles.side}>
                            <div className={styles.image}>
                                <img src={fixedAsset?.image} height={50} width={75}/>
                            </div>
                            <div className={styles.actions}>
                                <Button><Link to={`/fa-detail/${fixedAsset?.id}`}>Detay</Link></Button>
                                <Button onClick={() => {
                                    openNotification({
                                        api: notificationApi,
                                        title: 'Emin Misiniz ?',
                                        description: 'Bu sabit kıymet ürününü silmek istediğinizden emin misinzi?',
                                        onAccept: () => {
                                            dispatch(deleteFixedAsset(fixedAsset?.id));
                                        },
                                        onAcceptTitle: 'Sil',
                                        onDecline: null,
                                        onDeclineTitle: 'Vazgeç'
                                    });
                                }}>Sil</Button>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <h1>{fixedAsset?.barcode} | {fixedAsset?.employeeFirstName} {fixedAsset?.employeeLastName}</h1>
                            <h3>{fixedAsset?.productGroupName}, {fixedAsset?.productSubGroupName}, {fixedAsset?.productSubSubGroupName}</h3>
                            <div className={styles.description}>
                                <span>{fixedAsset?.brand ? fixedAsset?.brand : <i>Marka yok</i>},</span>
                                <span>{fixedAsset?.model ? fixedAsset?.model : <i>Model yok</i>},</span>
                                <span>{fixedAsset?.serialNo ? fixedAsset?.serialNo : <i>Seri No yok</i>},</span>
                                <span>{fixedAsset?.description ? fixedAsset?.description : <i>Açıklama yok</i>},</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {notificationContextHolder}

        </section>
    </Layout>
}

export default DashboardPage;
