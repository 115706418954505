import {Fragment, useEffect} from "react";
import Login from '@pages/Login';
import {useSelector} from "react-redux";
import CountingLocation from "@pages/CountingLocation";

function RequireAuth({
                         children,
                         isAuthenticated,
                         user
                     }: { children: JSX.Element, isAuthenticated: boolean, user: any }) {

    const isCountingSelected = useSelector((state: any) => state.core.isCountingSelected);

    useEffect(() => {
        // authContext.setUser(user);
    }, [user]);

    if (isAuthenticated === undefined) {
        return <Fragment/>;
    } else {
        return (isAuthenticated ? ((isCountingSelected === true) ? children :
            <CountingLocation></CountingLocation>) : <Login></Login>)
    }
}

export default RequireAuth;
