import {
    SET_SELECTED_COUNTING,
    GET_COMPANIES,
    GET_COMPANIES_REQUEST,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_FAILED,
    GET_BRANCHES,
    GET_BRANCHES_REQUEST,
    GET_BRANCHES_SUCCESS,
    GET_BRANCHES_FAILED,
    GET_UNITS,
    GET_UNITS_REQUEST,
    GET_UNITS_SUCCESS,
    GET_UNITS_FAILED,
    GET_SUB_UNITS,
    GET_SUB_UNITS_REQUEST,
    GET_SUB_UNITS_SUCCESS,
    GET_SUB_UNITS_FAILED,
    SET_IS_COUNTING_SELECTED,
    ERROR_OCCURRED,
    CLEAR_ERROR
} from '../actions/Core.actions';

const coreState = {
    companies: null,
    branches: null,
    units: null,
    subUnits: null,
    selectedCounting: {
        location: null,
        branch: null,
        unit: null,
        subUnit: null
    },
    isCountingSelected: null,
    loading: null,
    error: null
}

const CoreReducer = (state = coreState, action) => {
    const {type, payload, data} = action;

    switch (type) {
        case SET_SELECTED_COUNTING:
            return {
                ...state,
                selectedCounting: {
                    ...state.selectedCounting,
                    ...payload
                }
            };
        case GET_COMPANIES:
            return {...state};
        case GET_COMPANIES_REQUEST:
            return {
                ...state,
                companies: null,
                loading: true,
                error: false
            };
        case GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: data,
                loading: false,
                error: false
            };
        case GET_COMPANIES_FAILED:
            return {
                ...state,
                companies: null,
                loading: false,
                error: data
            };
        case GET_BRANCHES:
            return {...state};
        case GET_BRANCHES_REQUEST:
            return {
                ...state,
                branches: null,
                loading: true,
                error: false
            };
        case GET_BRANCHES_SUCCESS:
            return {
                ...state,
                branches: data,
                loading: false,
                error: false
            };
        case GET_BRANCHES_FAILED:
            return {
                ...state,
                branches: null,
                loading: false,
                error: data
            };
        case GET_UNITS:
            return {...state};
        case GET_UNITS_REQUEST:
            return {
                ...state,
                units: null,
                loading: true,
                error: false
            };
        case GET_UNITS_SUCCESS:
            return {
                ...state,
                units: data,
                loading: false,
                error: false
            };
        case GET_UNITS_FAILED:
            return {
                ...state,
                units: null,
                loading: false,
                error: data
            };
        case GET_SUB_UNITS:
            return {...state};
        case GET_SUB_UNITS_REQUEST:
            return {
                ...state,
                subUnits: null,
                loading: true,
                error: false
            };
        case GET_SUB_UNITS_SUCCESS:
            return {
                ...state,
                subUnits: data,
                loading: false,
                error: false
            };
        case GET_SUB_UNITS_FAILED:
            return {
                ...state,
                subUnits: null,
                loading: false,
                error: data
            };
        case SET_IS_COUNTING_SELECTED:
            return {
                ...state,
                isCountingSelected: payload
            }
        case ERROR_OCCURRED:
            return {
                ...state,
                ...payload
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

export default CoreReducer;
