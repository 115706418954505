import React from 'react';
import styles from '@styles/components/MiniCard.module.scss';

const MiniCard: React.FC<any> = ({title, label}) => {
    return <div className={styles.mini_card}>
        <h2 className={styles.title}>{title}</h2>
        <label className={styles.label}>{label}</label>
    </div>
}

export default MiniCard;
