import {
    LOGIN,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    GET_ME,
    GET_ME_SUCCESS,
    GET_ME_FAILED,
    VALIDATE_EMAIL,
    VALIDATE_EMAIL_REQUEST,
    VALIDATE_EMAIL_SUCCESS,
    VALIDATE_EMAIL_FAILED,
    REGISTER,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILED,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILED,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILED,
    FORGOT_PASSWORD_VERIFY,
    FORGOT_PASSWORD_VERIFY_REQUEST,
    FORGOT_PASSWORD_VERIFY_SUCCESS,
    FORGOT_PASSWORD_VERIFY_FAILED,
    SET_NETWORK_STATUS,
    RESET_NETWORK_STATUS,
    REFRESH_TOKEN,
    SET_COUNTING_LOCATION,
    SET_IS_LOCATED,
    ERROR_OCCURRED,
    CLEAR_ERROR
} from '../actions/Auth.actions';

const authState = {
    isLoggedIn: null,
    networkStatus: true,
    token: null,
    user: null,
    countingLocation: null,
    isLocated: null,
    loading: null,
    error: null
}

const AuthReducer = (state = authState, action) => {
    const {type, payload, data} = action;

    switch (type) {
        case LOGIN:
            return {...state};
        case LOGIN_REQUEST:
            return {
                ...state,
                user: null,
                token: null,
                refreshToken: null,
                isLoggedIn: false,
                loading: true,
                error: false
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: data,
                token: data.token,
                refreshToken: data.refreshToken,
                isLoggedIn: true,
                loading: false,
                error: false
            };
        case LOGIN_FAILED:
            return {
                ...state,
                user: null,
                token: null,
                refreshToken: null,
                isLoggedIn: false,
                loading: false,
                error: data
            };
        case LOGOUT:
            return {...state};
        case LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                user: null,
                token: null,
                refreshToken: null,
                loading: false,
                countingLocation: null,
                isLocated: null,
                isLoggedIn: false,
                error: null
            };
        case LOGOUT_FAILED:
            return {
                ...state,
                error: data
            };
        case GET_ME:
            return {...state}
        case GET_ME_SUCCESS:
            return {
                ...state,
                user: data,
                isLoggedIn: true,
                loading: false,
                error: false
            }
        case GET_ME_FAILED:
            return {
                ...state,
                user: null,
                token: null,
                refreshToken: null,
                isLoggedIn: false,
                loading: false
            }
        case VALIDATE_EMAIL:
            return {...state}
        case VALIDATE_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            };
        case VALIDATE_EMAIL_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    isVerified: true
                },
                loading: false,
                error: false
            }
        case VALIDATE_EMAIL_FAILED:
            return {
                ...state,
                loading: false,
                error: data
            }
        case REGISTER:
            return {...state};
        case REGISTER_REQUEST:
            return {
                ...state,
                user: null,
                token: null,
                refreshToken: null,
                isLoggedIn: false,
                loading: true,
                error: false
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                user: data,
                token: data.token,
                refreshToken: data.refreshToken,
                isLoggedIn: true,
                loading: false,
                error: false
            };
        case REGISTER_FAILED:
            return {
                ...state,
                user: null,
                token: null,
                refreshToken: null,
                isLoggedIn: false,
                loading: false,
                error: data
            };
        case CHANGE_PASSWORD:
            return {...state};
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };
        case CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                error: data
            };
        case FORGOT_PASSWORD:
            return {...state};
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                user: null,
                token: null,
                refreshToken: null,
                isLoggedIn: false,
                loading: true,
                error: false
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };
        case FORGOT_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                error: data
            };
        case FORGOT_PASSWORD_VERIFY:
            return {...state};
        case FORGOT_PASSWORD_VERIFY_REQUEST:
            return {
                ...state,
                user: null,
                token: null,
                refreshToken: null,
                isLoggedIn: false,
                loading: true,
                error: false
            };
        case FORGOT_PASSWORD_VERIFY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };
        case FORGOT_PASSWORD_VERIFY_FAILED:
            return {
                ...state,
                loading: false,
                error: data
            };
        case SET_NETWORK_STATUS:
            return {
                ...state,
                networkStatus: payload
            }
        case RESET_NETWORK_STATUS:
            return {
                ...state,
                networkStatus: undefined
            }
        case REFRESH_TOKEN:
            return {
                ...state,
                ...payload
            }
        case SET_COUNTING_LOCATION:
            return {
                ...state,
                countingLocation: {
                    ...payload
                },
            }
        case SET_IS_LOCATED:
            return {
                ...state,
                isLocated: payload
            }
        case ERROR_OCCURRED:
            return {
                ...state,
                ...payload
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

export default AuthReducer;
