import React, {useEffect, useState} from 'react';

import styles from '@styles/pages/NewFixedAsset.module.scss';
import {Button, Checkbox, Form, Input, notification, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from 'antd';

import {
    createFixedAsset,
    fetchNewFaData,
    getProductSubGroups,
    getProductSubSubGroups, updateFixedAsset
} from "@redux/actions/FixedAssets.actions";
import {AddPictureIcon, BackIcon} from "@components/molecules/Icons";
import alert from "@utils/alert";
import {Link, useParams} from "react-router-dom";
import uploadS3File from "@utils/uploadS3File";

const {Option} = Select;

const FixedAssetPage: React.FC<any> = () => {
    const {faId} = useParams();

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const {
        fixedAssets,
        employees,
        productGroups,
        productSubGroups,
        productSubSubGroups
    } = useSelector((state: any) => state.fixedAssets);
    const {selectedCounting} = useSelector((state: any) => state.core);

    const [extraDescriptionToggle, setExtraDescriptionToggle] = useState(false);
    const [newFaPicture, setNewFaPicture] = useState(undefined);

    useEffect(() => {
        dispatch(fetchNewFaData(null));
        const fa = fixedAssets?.find(el => el.id === Number(faId));
        console.log('FA', fa);

        setNewFaPicture(fa?.image);

        form.setFieldsValue({
            image: fa?.image,
            barcode: fa?.barcode,
            productStatus: fa?.productStatus,
            expenseType: fa?.expenseType,
            productGroup: fa?.productGroupId,
            productSubGroup: fa?.productSubGroupId,
            productSubSubGroup: fa?.productSubSubGroupId,
            brand: fa?.brand,
            model: fa?.model,
            serialNo: fa?.serialNo,
            employee: fa?.employeeId,
            description: fa?.description,
            isMainFa: fa?.isMainFa,
            mainFaId: fa?.mainFaId,
            isActive: true
        });

    }, []);

    const onProductGroupChange = (value) => {
        form.setFieldsValue({
            ...form.getFieldsValue(),
            productSubGroups: undefined,
            productSubSubGroups: undefined
        });
        dispatch(getProductSubGroups(value));
    }

    const onProductSubGroupChange = (value) => {
        form.setFieldsValue({
            ...form.getFieldsValue(),
            productSubSubGroups: undefined
        });
        dispatch(getProductSubSubGroups(value));
    }

    const onPictureChange = (_e) => {
        uploadS3File(`${process.env.REACT_APP_PANEL_URL + '/api/s3-uploads'}?folderName=${selectedCounting?.location?.value}`, _e.target.files[0], (response) => {
            if (response) {
                console.log('RESRES', response);
                form.setFieldsValue({
                    ...form.getFieldsValue(),
                    image: response.location // e.target.files[0]
                });
                setNewFaPicture(response.location);
            } else {
                alert('error', {description: 'Resim yüklenemedi.'});
            }
        });
    }

    const onProductStatusChange = () => {

    }

    const onFinish = (values: any) => {
        console.log('vl', values);

        dispatch(updateFixedAsset(faId, {
            ...values,
            description2: values.description2 || ''
        }, () => {
            alert('success', {description: 'Sabit Kıymet başarılı bir şekilde güncellendi.'});
        }));
    };

    const onReset = () => {
        form.resetFields();
    };

    const selectFilterOption = (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0

    return <section className={styles.new_fa_container}>
        <div className={styles.new_fa_form}>
            <h1 className={styles.title}>
                <Link to={"/"} className={styles.icon}>
                    <BackIcon/>
                </Link>
                Sabit Kıymet Detay Formu
            </h1>

            <Form
                name="basic"
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 600}}
                form={form}
                className={styles.new_fa_form_content}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Ürün Resmi"
                            name="image"
                            rules={[{required: true, message: 'Ürün resmi ekleyiniz'}]}
                        >
                            <label htmlFor={"add_picture_input"}>
                                <div className={styles.add_picture_container}>
                                    {newFaPicture ?
                                        <img alt={"new_fa_picture"} width={50} height={50}
                                             src={form.getFieldValue('image')}/>
                                        :
                                        <AddPictureIcon/>
                                    }
                                </div>
                            </label>
                            <input onChange={onPictureChange} className={"d-none"} style={{display: 'none'}}
                                   id={"add_picture_input"}
                                   type={"file"}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Barkodu"
                            name="barcode"
                            rules={[{required: true, message: 'Lütfen barkod giriniz'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item name="productStatus" label="Ürün Durumu" rules={[{required: true}]}>
                            <Select
                                placeholder="Ürünün durumunu belirleyiniz"
                                onChange={onProductStatusChange}
                                allowClear
                            >
                                <Option value="Sağlam">Sağlam</Option>
                                <Option value="Arızalı">Arızalı</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="expenseType" label="Gider Türü" rules={[{required: true}]}>
                            <Select
                                placeholder="Gider türü belirleyiniz"
                                allowClear
                            >
                                <Option value="Capex">Capex</Option>
                                <Option value="Opex">Opex</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="productGroup" label="Ürün Grupları" rules={[{required: true}]}>
                    <Select
                        showSearch
                        filterOption={selectFilterOption}
                        placeholder={"Ürün grubu seçiniz"}
                        className={"select"}
                        onChange={onProductGroupChange}
                        options={Array.isArray(productGroups) && productGroups?.map((pg) => ({
                            label: pg.name,
                            value: pg.id
                        }))}
                        allowClear
                    />
                </Form.Item>

                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item name="productSubGroup" label="Ürün Alt Grupları" rules={[{required: true}]}>
                            <Select
                                showSearch
                                filterOption={selectFilterOption}
                                placeholder={"Ürün alt grubu seçiniz"}
                                className={"select"}
                                onChange={onProductSubGroupChange}
                                options={Array.isArray(productSubGroups) && productSubGroups?.map((psg) => ({
                                    label: psg.name,
                                    value: psg.id
                                }))}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="productSubSubGroup" label="Alt Grubun Alt Grupları"
                                   rules={[{required: true}]}>
                            <Select
                                showSearch
                                filterOption={selectFilterOption}
                                placeholder={"Alt grubun alt grubunu seçiniz"}
                                className={"select"}
                                options={Array.isArray(productSubSubGroups) && productSubSubGroups?.map((pssg) => ({
                                    label: pssg.name,
                                    value: pssg.id
                                }))}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            label="Marka"
                            name="brand"
                            rules={[{required: false, message: 'Marka yazınız'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Model"
                            name="model"
                            rules={[{required: false, message: 'Model yazınız'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            label="Seri No"
                            name="serialNo"
                            rules={[{required: false, message: 'Seri No yazınız'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Çalışan"
                            name="employee"
                            rules={[{required: true, message: 'Çalışan giriniz'}]}
                        >
                            <Select
                                showSearch
                                filterOption={selectFilterOption}
                                placeholder={"Çalışan seçiniz"}
                                className={"select"}
                                options={Array.isArray(employees) && employees?.map((e) => ({
                                    label: `${e.first_name} ${e.last_name}`,
                                    value: e.id
                                }))}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    label="Açıklama"
                    name="description"
                    rules={[{required: true, message: 'Açıklama yazınız'}]}
                >
                    <Input.TextArea/>
                </Form.Item>

                <Checkbox id={'extraDescriptionCheckbox'} onChange={() => {
                    setExtraDescriptionToggle(!extraDescriptionToggle);
                }} checked={extraDescriptionToggle}>Ek Açıklama</Checkbox>

                {extraDescriptionToggle === true &&
                  <Form.Item
                    label="Açıklama 2"
                    name="description2"
                    rules={[{required: false, message: 'Açıklama 2 yazınız'}]}
                  >
                    <Input.TextArea/>
                  </Form.Item>
                }

                <Form.Item label="isActive" name="isActive" hidden/>
                <Form.Item label="isMainFa" name="isMainFa" hidden/>
                <Form.Item label="mainFaId" name="mainFaId" hidden/>

                <Row className={'pbs-20 pbe-10'}>
                    <Col span={24}>
                        <Button className={'w-100'} type="primary" htmlType="submit">
                            Güncelle
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    </section>
}

export default FixedAssetPage;
