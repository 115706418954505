import React, {useEffect, useState} from 'react';
import styles from '@styles/pages/CountingLocation.module.scss';
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
    setSelectedCounting,
    getCompanies,
    getBranches,
    getUnits,
    getSubUnits,
    getUnitsSuccess, getSubUnitsSuccess, setIsCountingSelected
} from "@redux/actions/Core.actions";
import {Button, Select} from 'antd';

const CountingLocationPage: React.FC<any> = () => {
    let navigate = useNavigate();

    const {selectedCounting, companies, branches, units, subUnits} = useSelector((state: any) => state.core);
    const {isLoggedIn} = useSelector((state: any) => state.auth);
    const dispatch = useDispatch();

    const [validation, setValidation] = useState(false);

    useEffect(() => {
        if (isLoggedIn !== true) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        dispatch(getCompanies());
    }, []);

    useEffect(() => {
        if (selectedCounting.location && selectedCounting.branch && selectedCounting.unit && selectedCounting.subUnit) {
            setValidation(true);
        } else {
            setValidation(false);
        }
    }, [selectedCounting]);

    const handleLocationChange = (value, option) => {
        dispatch(setSelectedCounting({
            location: option,
            branch: null,
            unit: null,
            subUnit: null
        }));
        dispatch(getUnitsSuccess([]));
        dispatch(getSubUnitsSuccess([]));
        dispatch(getBranches(value))
    };

    const handleBranchChange = (value, option) => {
        dispatch(setSelectedCounting({
            branch: option,
            unit: null,
            subUnit: null
        }));
        dispatch(getSubUnitsSuccess([]));
        dispatch(getUnits(selectedCounting.location.value, value));
    };

    const handleUnitChange = (value, option) => {
        dispatch(setSelectedCounting({
            unit: option,
            subUnit: null
        }));
        dispatch(getSubUnits(value));
    };

    const handleSubUnitChange = (_value, option) => {
        dispatch(setSelectedCounting({subUnit: option}));
    };

    return <section className={styles.counting_location}>
        <div className={styles.container}>
            <div className={styles.logo}>Şube/Lokasyon Seçiniz</div>
            <form id={"countingLocationForm"} className={styles.counting_location_form}>

                <div className={"form-group"}>
                    <label>Lokasyon</label>
                    <Select
                        className={"select"}
                        onChange={handleLocationChange}
                        value={selectedCounting.location}
                        options={Array.isArray(companies) && companies?.map((c) => ({label: c.name, value: c.id}))}
                    />
                </div>

                <div className={"form-group"}>
                    <label>Şube</label>
                    <Select
                        className={"select"}
                        onChange={handleBranchChange}
                        value={selectedCounting.branch}
                        options={Array.isArray(branches) && branches?.map((b) => ({label: b.name, value: b.id}))}
                    />
                </div>

                <div className={"form-group"}>
                    <label>Birim</label>
                    <Select
                        className={"select"}
                        onChange={handleUnitChange}
                        value={selectedCounting.unit}
                        options={Array.isArray(units) && units?.map((u) => ({label: u.name, value: u.id}))}
                    />
                </div>

                <div className={"form-group"}>
                    <label>Alt Birim</label>
                    <Select
                        className={"select"}
                        onChange={handleSubUnitChange}
                        value={selectedCounting.subUnit}
                        options={Array.isArray(subUnits) && subUnits?.map((su) => ({label: su.name, value: su.id}))}
                    />
                </div>

                <div className={"form-actions w-100"}>
                    <Button type={"primary"} form={"loginForm"} disabled={!validation} onClick={() => {
                        dispatch(setIsCountingSelected(true));
                        navigate('/');
                    }}>İlerle
                    </Button>
                </div>
            </form>
        </div>
    </section>
}

export default CountingLocationPage;
